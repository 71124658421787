<template>
  <div class="AiCreationDocment">
    <div class="content">
      <div class="Introductory_title">
        <div class="img_box">
          <img :src="modulTypeObj.image" alt="" />
        </div>
        <div class="item_text">{{ modulTypeObj.value }}</div>
      </div>
      <div class="formTitle">
        <div class="checkout_star">*</div>
        {{ radioOptions.find((obj) => obj.value == modulType)?.modulTitle }}：
      </div>
      <div class="item title2">
        <div class="right">
          <div class="inputBox">
            <a-col :span="24">
              <a-input
                :maxLength="50"
                size="small"
                v-model="inputValue"
                class="input-class-left"
                :placeholder="
                  radioOptions.find((obj) => obj.value == modulType)
                    ?.placeholderTitle
                "
              />
            </a-col>
          </div>
        </div>
      </div>

      <div
        class="formTitle"
        v-if="radioOptions.find((obj) => obj.value == modulType)?.modulTitle2"
      >
        <div class="checkout_star">*</div>
        {{ radioOptions.find((obj) => obj.value == modulType)?.modulTitle2 }}：
      </div>
      <div
        class="item title2"
        v-if="radioOptions.find((obj) => obj.value == modulType)?.modulTitle2"
      >
        <div class="right">
          <div class="inputBox">
            <a-col :span="24">
              <a-input
                :maxLength="50"
                size="small"
                v-model="inputValue2"
                class="input-class-left"
                :placeholder="
                  radioOptions.find((obj) => obj.value == modulType)
                    ?.placeholderTitle2
                "
              />
            </a-col>
          </div>
        </div>
      </div>

      <div class="formTitle">
        <div class="checkout_star">*</div>
        {{ radioOptions.find((obj) => obj.value == modulType)?.modulArea }}：
      </div>
      <div
        class="textArea"
        :class="modulType == '推广方案' ? 'textArea_changeHeight' : ''"
      >
        <div class="area">
          <div class="area-box">
            <a-textarea
              :maxlength="500"
              v-model="textAreaValue"
              class="ai-textarea"
              :placeholder="
                radioOptions.find((obj) => obj.value == modulType)
                  ?.placeholderArea
              "
            />
          </div>
        </div>
      </div>

      <div
        @click="createdDoc"
        class="creation-button"
        :class="{
          creation_creationFlag: creationFlag,
          creation_creationLoadingFlag: creationLoadingFlag,
          creation_transferFlag: transferFlag,
        }"
      >
        {{ subBtnTxt }}
        <div class="dot-box" v-if="creationLoadingFlag">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </div>

    <div class="content-right">
      <div v-show="!editcrCationFlag" class="createFile_noInput">
        <div class="img_box">
          <img src="@/assets/images/ai_document_create_empty.png" alt="" />
        </div>
        <div class="empty_text">还没有内容生成</div>
        <div class="empty_text">
          在页面左侧输入您的内容诉求，快速生成您想要的文档内容
        </div>
      </div>

      <div class="Introductory_title">
        <div class="img_box">
          <img src="@/assets/images/ai_document_create.png" alt="" />
        </div>
        <div class="item_text">生成文档内容</div>

        <div
          class="saveBtn"
          @click="saveFile"
          :class="
            !creationLoadingFlag &&
            editcrCationFlag &&
            transferFlag &&
            contentEmptyFlag
              ? 'saveBtn_loading'
              : 'saveBtn_default'
          "
        >
          <div class="img_box">
            <img src="@/assets/images/ai_document_save_btn.png" alt="" />
          </div>
          <div>保 存</div>
        </div>
      </div>

      <div class="area-box-edit">
        <div
          v-show="editcrCationFlag"
          :contenteditable="transferFlag ? true : false"
          v-html="docContentEdit"
          :class="transferFlag ? 'ai-doc-edit' : 'ai-doc-render'"
          id="ai-doc-content"
          @input="handleContentChange"
        ></div>
      </div>
    </div>

    <aiFileModule
      :modulType="modulType"
      :inputValue="inputValue"
      ref="aiFileModule"
      @submit="submit"
    ></aiFileModule>
  </div>
</template>

<script>
import aiFileModule from "./components/aiFileModule";
import Header from "../../components/layouts/Header";
import MarkdownIt from "markdown-it";
// import Vditor from './components/lute.js';
// import  './components/vditor.css';

export default {
  name: "VIEW_AiCreationDocment",
  components: {
    aiFileModule,
    Header,
  },
  props: {},
  data() {
    return {
      md: "",
      docContentEdit: "",
      contentEmptyFlag: true,
      subBtnTxtFlag: true,
      count: 0,
      renderedContent: "", // 存储已渲染的内容
      loading: false,
      transferFlag: false, // 是否传输完成，初始没有生产文档，为false
      creationLoadingFlag: false, // 是否正在生成中
      editcrCationFlag: false, // 是否可点击生成文档状态
      subBtnTxt: "生成文档内容",
      // vditor: null,
      MarkdownAreaValue: "",
      publicWord:
        "请以markdown格式输出,标题:居中对齐,微软雅黑,加粗,二号;正文;左对齐,微软雅黑,加粗,11号",
      flag: true,
      flag2: true,
      title: "",
      content: "",
      inputValue: "",
      inputValue2: "",
      textAreaValue: "",
      modulType: "发言稿",
      // creationFlag: false,
      visible: true,
      selectedValue: "",
      radioOptions: [
        {
          value: "发言稿",
          label: "发言稿",
          modulTitle: "目标群体",
          modulArea: "发言内容",
          placeholderTitle: "请输入，如：师生、员工、亲朋好友",
          placeholderArea:
            "请简要描述您的发言内容，如：开学致词，感谢同学老师的信任",
          image: require("@/assets/images/Statements.png"),
        },
        {
          value: "论文写作",
          label: "论文写作",
          modulTitle: "论文标题",
          modulArea: "论文关键内容",
          placeholderTitle: "请输入，如：人工智能的影响",
          placeholderArea:
            "请简要描述您论文的关键内容，如：人工智能的发展历程、人工智能的应用",
          image: require("@/assets/images/thesis_writing.png"),
        },
        {
          value: "需求文档",
          label: "需求文档",
          modulTitle: "功能名称",
          modulArea: "需求描述",
          placeholderTitle: "请输入，如：会员体系设计",
          placeholderArea:
            "请简要描述您的需求内容，如：通过会员体系，促进用户增长～",
          image: require("@/assets/images/requirement_document.png"),
        },
        {
          value: "产品介绍",
          label: "产品介绍",
          modulTitle: "产品名称",
          modulArea: "产品亮点",
          placeholderTitle: "请输入，如：数智365-Ai文档",
          placeholderArea:
            "请简要描述您的产品亮点，如：可智能生成word文档、使用类别丰富多样",
          image: require("@/assets/images/product.png"),
        },
        {
          value: "推广方案",
          label: "推广方案",
          modulTitle: "需要推广的产品名称",
          modulTitle2: "目标推广人群",
          modulArea: "产品亮点",
          placeholderTitle: "请输入，如：数智365-Ai文档",
          placeholderTitle2: "请输入，如：销售、财务、策划",
          placeholderArea:
            "请简要描述您的产品亮点，如：可智能生成word文档、使用类别丰富多样",
          image: require("@/assets/images/promotion_scheme.png"),
        },
        {
          value: "生成文档",
          label: "生成文档",
          modulTitle: "请输入文档标题",
          modulArea: "文档核心内容",
          placeholderTitle: "请输入文档标题",
          placeholderArea: "请简要描述您想要生成的文档内容～",
          image: require("@/assets/images/Custom_document.png"),
        },
        // Add more options as needed
      ],
      editorOptions: {
        toolbar: true, // 隐藏编辑器的菜单栏
      },
    };
  },
  computed: {
    modulTypeObj() {
      return this.radioOptions.find((obj) => obj.value === this.modulType);
    },
    // 是否显示了ai创建界面
    aicreationFlag() {
      return this.$store.state.user.showAicreationFlag;
    },
    aicreationmodulType() {
      return this.$store.state.user.aicreationmodulType;
    },

    // 是否可以点击生成文档
    creationFlag() {
      if (this.modulType !== "推广方案") {
        if (this.inputValue.length && this.textAreaValue.length) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.inputValue.length &&
          this.textAreaValue.length &&
          this.inputValue2.length
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    // 提示词
    callWord() {
      if (this.modulType == "发言稿") {
        return `请帮我写一篇发言稿，标题为：发言稿，针对的目标群体是:${this.inputValue}，发言内容主要包含：${this.textAreaValue},${this.publicWord}`;
      } else if (this.modulType == "论文写作") {
        return `请帮我写一篇论文写作，论文标题是${this.inputValue}，论文关键内容包含${this.textAreaValue},${this.publicWord}`;
      } else if (this.modulType == "需求文档") {
        return `请帮我写一篇需求文档，功能名称：${this.inputValue}，需求描述：${this.textAreaValue},${this.publicWord}`;
      } else if (this.modulType == "产品介绍") {
        return `请帮我写一篇产品介绍，产品名称：${this.inputValue}，产品亮点：${this.textAreaValue},${this.publicWord}`;
      } else if (this.modulType == "推广方案") {
        return `请帮我写一篇推广方案，需要推广的产品名称：${this.inputValue}，目标推广人群：${this.inputValue2}，产品亮点：${this.textAreaValue},${this.publicWord}`;
      } else if (this.modulType == "生成文档") {
        return `请帮我写一篇文档，文档标题：${this.inputValue}，文档核心内容：${this.textAreaValue},${this.publicWord}`;
      }
    },
    // 租户id 企业id
    companyId() {
      return this.$store.state.user.companyId;
    },
    token() {
      return this.$store.state.user.token;
    },
  },
  watch: {
    aicreationmodulType(v) {
      // 初始化
      this.editcrCationFlag = false;
      this.selectOption({ value: v });
    },
    editcrCationFlag(val) {
      if (!val) {
        this.$nextTick(() => {
          this.docContentEdit = "";
        });
      }
    },

    aicreationFlag(val) {
      console.log("----------val", val);
      if (!val) {
        this.modulType == "发言稿";
        this.inputValue = "";
        this.inputValue2 = "";
        this.textAreaValue = "";
        this.editcrCationFlag = false;
      } else {
        this.$nextTick(() => {
          if (this.vditor) {
            this.vditor.setValue("");
          } else {
            this.initVditor();
          }
        });
      }
    },

    visible(newVal) {
      if (!newVal) {
        this.selectedValue = "";
        // console.log("🚀 ~ file: index.vue:83 ~ visible ~ newVal:", newVal)
      } else {
        this.selectedValue = this.modulType;
      }
    },
    selectedValue(newVal) {
      if (newVal) {
        console.log(
          "🚀 ~ file: index.vue:83 ~ selectedValue ~ newVal:",
          newVal
        );
        this.modulType = newVal;
        console.log("🚀 ~ this.modulType:", this.modulType);
        // this.visible=false
        // console.log("🚀 ~ file: index.vue:83 ~ selectedValue ~ newVal:", newVal)
      }
    },
  },
  mounted() {
    // console.log("🚀 ~ Vditor-----------------------------------", this.Vditor)

    // this.initVditor()

    this.initMd();
  },
  beforeDestroy() {
    // 组件销毁前，确保销毁 vditor 实例
    if (this.vditor) {
      this.vditor = null;
    }
  },
  methods: {
    handleContentChange() {
      const contentDiv = document.getElementById("ai-doc-content");
      if (contentDiv && contentDiv.innerText.trim() === "") {
        // 如果内容被完全删除，执行您想要的操作
        console.log("内容已被完全删除");
        this.contentEmptyFlag = false;
        // 例如，您可以设置一个标志或执行其他逻辑
      } else {
        this.contentEmptyFlag = true;
      }
    },
    async scrollWatch() {
      await this.$nextTick();
      const dom = document.getElementById("ai-doc-content");
      const clientHeight = Math.ceil(dom.clientHeight);
      const scrollHeight = Math.ceil(dom.scrollHeight);
      if (scrollHeight > clientHeight) {
        dom.scrollTop = scrollHeight - clientHeight;
      }
    },
    initMd() {
      this.md = new MarkdownIt({
        html: true, // 允许HTML标签
        highlight: function (/*str, lang*/) {
          return "";
        },
      });
    },
    replaceMsg(text) {
      // 正则表达式，匹配<pre><code class="language-markdown">...</code></pre>以及后面的内容
      const regex =
        /<pre><code class="language-markdown">([\s\S]*?)<\/code><\/pre>([\s\S]*)/;

      // 使用replace方法，但不替换匹配的内容，只保留括号内的内容
      const result = text.replace(
        regex,
        (_, contentInsideTags, contentAfterTags) => {
          // 返回保留的内容，contentInsideTags是标签内的内容，contentAfterTags是标签后的内容
          return contentInsideTags.trim() + contentAfterTags.trim();
        }
      );

      return result
    },
    markdownToHTML(text) {
      return this.replaceMsg(this.md.render(text));
    },

    toggleOptions() {
      this.visible = !this.visible;
    },
    selectOption(item) {
      console.log("item", item.value);

      if (this.modulType == item.value) {
        return;
      } else {
        setTimeout(() => {
          this.subBtnTxtFlag = false;
          this.visible = false;
          this.subBtnFlag = true;
          this.editcrCationFlag = false;
          this.creationLoadingFlag = false;
          this.subBtnTxt = "生成文档内容";
          // 置空输入条件
          this.inputValue = "";
          this.inputValue2 = "";
          this.textAreaValue = "";
        }, 100);
      }

      this.modulType = item.value;
      this.selectedValue = item.value;
      console.log("this.selectedValue----", this.selectedValue);
      setTimeout(() => {
        this.visible = false;
        this.subBtnFlag = true;
        this.editcrCationFlag = false;
        this.creationLoadingFlag = false;
        this.subBtnTxt = "生成文档内容";
        // 置空输入条件
        this.inputValue = "";
        this.inputValue2 = "";
        this.textAreaValue = "";
      }, 500);
      this.visible = false;
    },
    saveFile() {
      this.$refs.aiFileModule.show();
    },

    async submit(val) {
      // debugger
      this.$emit("loadingStatus", true);
      // this.loading=true
      let query = {
        ...val,
        // content: this.vditor.getValue(),
        content: this.docContentEdit,
        source:'AI',
      };

      try {
        const aiFileRes = await this.$apis.saveCreateAiFile(query);
        if (aiFileRes) {
          this.$emit("loadingStatus", false);
          // this.loading=false
          // let query = {
          //   _t: new Date() - 0
          // }
          // this.$router.push({ path: "/star" ,query});
          // this.$router.go(0);
          this.$message.success("保存成功");
          // location.reload();
          this.$store.commit("user/setShowAicreationFlag", false);
          // this.$router.push({ name: 'VIEW_COMPANYTEAM_DOCUMENT' });
          let query = {
            submitAiCreation: true,
            _t: new Date() - 0,
            id: val.parentId,
          };
          if (val.moduleType === "doc") {
            // 个人
            this.$router.push({
              path: "/star/mydoc",
              query: { id: val.parentId },
            });
            return;
          } // 企业
          this.$router.push({
            path: "/star/companyTeam/document",
            query: { id: val.parentId, teamId: val.teamId },
          });
          return;
        }
      } catch (error) {
        this.$emit("loadingStatus", false);
      }
    },

    handleInput() {
      console.log("鼠标输入了文字");
      this.inputValue = this.inputValue.split("").reverse().join("");
    },

    // // 创建并初始化markDown编辑器
    // initVditor() {
    //   if (!this.vditor) {
    //     this.vditor = new Vditor("ai-vditor", {
    //       mode: "wysiwyg",
    //       width: "100%",
    //       hotkey: [],
    //       toolbar: [],
    //       // typewriterMode: true,  // 启用打字机模式
    //       toolbarConfig: {
    //         hotkey: [],
    //       },
    //       preview: {
    //         hljs: {
    //           style: "monokai",
    //           enable: true,
    //           lineNumber: true,
    //         },
    //       },
    //       input: (value) => {
    //         if (value === "" || value.trim() === "") {
    //           console.log("输入为空或只包含空白字符");
    //           this.contentEmptyFlag = false;
    //         } else {
    //           console.log("输入不为空且包含非空白字符");
    //           this.contentEmptyFlag = true;
    //         }
    //       },
    //     });
    //   }
    //   this.vditor.enable();
    // },

    scrollToBottom(el) {
      if (el) {
        // el.scrollTop = el.scrollHeight;
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    },

    // ② 用户token认证
    async setAiToken() {
      const result = await this.$apis.getAuth();
      if (result.code === 200) {
        return true; // 认证通过
      } else {
        return false; // 认证失败
      }
    },
    // ① 生成文档内容
    async createdDoc() {
      this.subBtnTxtFlag = true;
      this.editcrCationFlag = true;

      // console.log("提示词为:", this.callWord)

      // 如果提示词信息未完整,则不调用生成文档接口
      if (!this.creationFlag) {
        console.log("必填");
        return;
      }

      // console.log('this.creationLoadingFlag', this.creationLoadingFlag);
      // console.log('this.editcrCationFlag', this.editcrCationFlag);

      // if (!this.creationLoadingFlag && this.editcrCationFlag && this.subBtnTxt == '保存') {
      //     // 生成文档内容全部完成+是编辑模式
      //     // 此时点击可以调用接口生成文档
      //     // alert('99')

      //     this.$refs.aiFileModule.show();

      //     // this.$nextTick(() => {
      //     //     this.$refs.aiFileModule.fileName = this.inputValue // 假设需要回显的文件名称

      //     // });
      //     return
      // }

      const isAuthPassed = await this.setAiToken();
      // 认证不通过，拦截后续操作
      if (!isAuthPassed) {
        return;
      }
      let data = {
        prompt: this.callWord,
        options: {},
      };
      let header = {
        "Chat-Model": 3,
      };
      let xhr = "";
      let text = "";

      if (this.editcrCationFlag) {
        this.docContentEdit = "";

        //  动态渲染时禁用编辑器
        // if (this.vditor) {
        //   this.vditor.disabled();
        // }
        this.transferFlag = false;
        function splitChatMsg(resText) {
          if (!resText) return "";
          const lastIndex = resText.lastIndexOf("\n", resText.length - 2);
          let chunk = resText;
          let message = "";
          if (lastIndex !== -1) chunk = resText.substring(lastIndex);

          try {
            const data = JSON.parse(chunk);
            message = data.text;
          } catch (err) {
            console.error("序列化出错", err.message);
            return;
            // message = chunk
          }
          return message || "";
        }
        // 创建一个新的换行符

        // 记录渲染次数
        let count = 0;
        // 记录后端返回流式数据总次数
        let num = 0;
        const allRes = await this.$apis.aicreateDoc(data, header, (res) => {
          // console.log('后端每次返回的流式数据res',res);

          if (this.subBtnTxtFlag) {
            this.subBtnTxt = "生成中";
            this.creationLoadingFlag = true;
          }

          // 渲染次数
          count++;
          // 前一百次流式数据直接渲染，之后的双数渲染，单数过滤
          if (count > 100 && count % 2 !== 0) {
            return;
          }
          // 后端返回流式数据总次数
          num++;
          xhr = res.target;
          text = xhr.responseText;

          // console.log('text----------',text);
          // if (splitChatMsg(text).includes("null") || splitChatMsg(text).includes('{"rol")')) {
          //     // console.log("🚀 ~ file: index.vue:655 ~ allRes ~ res:", res)
          //     return
          // }
          let msg = splitChatMsg(text);
          console.log("---------msg", msg);
          // msg字符串，说明没有解析出正确的文本，直接过滤掉
          // 发现解析出"role":null'的错误解析，则过滤掉，返回其他异常乱码较少见，暂不过滤
          // if (!msg || msg.includes("null") || msg.includes('{"rol")') || msg.includes('{"role":')) {
          //     // 没有解析出正确的文本，直接过滤掉
          //     return
          // }

          this.docContentEdit = this.markdownToHTML(msg);
          console.log("最终结果的html :", this.docContentEdit);
          this.scrollWatch();
        });

        if (allRes) {
          let br = document.createElement("br");
          function moveCursorToEnd(element) {
            var range = document.createRange();
            var selection = window.getSelection();
            element.appendChild(br);
            // 将range的开始位置设定在换行符之后
            range.setStartAfter(br);
            // 清除所有的ranges并添加新的range
            selection.removeAllRanges();
            selection.addRange(range);
            // 为了防止滚动到顶部，设置元素的滚动位置
            element.scrollTop = element.scrollHeight;
          }

          this.creationLoadingFlag = false;
          this.transferFlag = true;
          this.contentEmptyFlag = true;
          console.log(
            "🚀 ~传输完成后的:creationLoadingFlag",
            this.creationLoadingFlag
          );
          console.log(
            "🚀 ~传输完成后的:editcrCationFlag",
            this.editcrCationFlag
          );
          console.log("全部传输完成了");
          // this.subBtnTxt = '保存'
          this.subBtnTxt = "生成文档内容";

          // 记录渲染内容给清空掉
          this.renderedContent = "";
          // xhr = allRes.target
          // text = xhr.responseText
          let msg = splitChatMsg(allRes);
          // console.log('allRes', msg);

          //   this.vditor && msg && this.vditor.setValue(msg);
          this.docContentEdit = this.markdownToHTML(msg);
          this.scrollWatch();
          setTimeout(() => {
            moveCursorToEnd(document.getElementById("ai-doc-content"));
          }, 300);

          // 将光标位置设置为文本末尾
          // this.vditor.setCursorPos(cursorPosition + msg.length);

          //   if (this.vditor) {
          //     this.vditor.enable();
          //   }

          console.log("返回了这么多次", count);
          console.log("渲染了这么多次", num);
        }
      }
    },

    // 点击单选切换模型
    handleRadioChange(val) {
      console.log("handleRadioChange ~ val:", val);
      setTimeout(() => {
        this.visible = false;
        this.subBtnFlag = true;
        this.editcrCationFlag = false;
        this.creationLoadingFlag = false;
        this.subBtnTxt = "生成文档内容";
        // 置空输入条件
        this.inputValue = "";
        this.inputValue2 = "";
        this.textAreaValue = "";
      }, 100);
    },

    getButtonClass(item) {
      // 根据选项的值来添加不同的类名

      if (item.value === this.selectedValue) {
        return "selected-button";
      } else {
        return "normal-button";
      }
    },
    confirm() {
      message.info("Clicked on Yes.");
    },
  },
};
</script>

<style lang="less">
[contenteditable="true"]:focus {
  outline: none;
}

// markdown编辑器区域
#ai-vditor {
  width: 100%;
  height: 100%;
  // min-height: 20px;

  .vditor-wysiwyg pre.vditor-reset[contenteditable="false"] {
    // opacity: 0.7 !important;
    opacity: 1 !important;
  }

  // caret-shape: 2px; // 设置光标宽度为2像素
  .vditor-reset {
    caret-color: #4a61ff !important;

    p {
      margin: 0;
    }
  }

  /* 自定义Vditor编辑器的样式 */
  .vditor-reset h1::before,
  .vditor-reset h2::before,
  .vditor-reset h3::before,
  .vditor-reset h4::before,
  .vditor-reset h5::before,
  .vditor-reset h6::before {
    // background-color: transparent !important; // 将标题的背景颜色设置为透明
    display: none;
  }

  .vditor-wysiwyg div.vditor-wysiwyg__block:before {
    display: none;
  }

  .vditor-wysiwyg pre.vditor-reset:after {
    display: none !important;
  }

  .vditor-reset {
    border: none !important;
    // min-height: 1500px;
    /* 去掉边框 */
  }

  .vditor-panel {
    display: none !important;
  }
}

// 下拉框样式修改

.ai_select .ant-select-selection {
  border-color: #ccc !important;
  /* 或者设置为与背景色相同的颜色 */
}

/* 当具有 .ai_select 类名的下拉框聚焦时，移除蓝色外框并设置阴影 */
.ai_select.ant-select-focused .ant-select-selection,
.ai_select .ant-select-selection:focus,
.ai_select .ant-select-selection:active {
  // border-color: #ccc !important;
  /* 或者你想要的其他颜色 */
  box-shadow: 0 0 0 1px rgba(228, 231, 237, 0.8) !important;
  // box-shadow: none !important;
  /* 设置阴影颜色和大小 */
}

// 下拉框上方选中内容区域样式
.ai_select {
  .ant-select-selection-selected-value {
    display: flex !important;
    align-items: center !important;

    img {
      width: 16px !important;
      /* 图片宽度为16px */
      height: 16px !important;
    }

    span {
      margin-left: 12px;
    }
  }
}

.ai_select_option.ant-select-dropdown-menu-item-selected {
  span {
    color: #4a61ff !important;
  }
}

// 下拉框内容区域样式
.ai_select_option {
  display: flex !important;
  /* 确保图片和文字在同一行 */
  align-items: center !important;
  /* 垂直居中 */
  text-align: left !important;

  /* 文字左对齐 */
  .ai_select_option_img {
    width: 16px !important;
    /* 图片宽度为16px */
    height: 16px !important;
    /* 图片高度为16px */
    // margin-right: 12px;
    /* 图片与文字之间的距离 */
  }

  .ai_select_option_span {
    /* 这里可以为文字添加需要的样式 */
    margin-left: 12px;
  }
}
</style>

<style lang="less" scoped>
.dot-box {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  background-color: #fff;
  border-radius: 50%;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
}

.selected-button {
  width: 160px;
  height: 50px;
  background: #f2f3f5;
  border-radius: 4px;
  margin-bottom: 0px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  // justify-content: center;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}

.normal-button {
  width: 160px;
  height: 50px;
  background: #ffffff;
  margin-bottom: 0px;
  border: 0;
  display: flex;
  // justify-content: center;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  span {
    user-select: none;
  }
}

.radio-group {
  width: 340px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .radio-group-row {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;

    .radio-group-col {
      width: 50%;
    }
  }
}

.radio-group .ant-radio-button-wrapper {
  margin-bottom: 0px;
}

::v-deep .content-right {
  // background: rgba(66, 63, 63, 0.1) !important;
}

::v-deep .area-box .ant-input {
  font-size: 14px !important;
  color: #111111 !important;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
}

.AiCreationDocment {
  width: 100%;
  // height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-top: 16px;
  // padding-top: 10px;

  // .header-box {
  //     width: 100%;
  //     height: 48px;
  //     position: relative;
  //     margin-bottom: 8px;
  // }

  .content {
    flex: 1;
    // margin: 0 auto;
    // margin-top: 16px;
    // width: 1000px;
    height: 100%;
    background-color: #fff;
    padding: 20px;

    .formTitle {
      margin-top: 22px;
      margin-bottom: 14px;
      display: flex;
      align-items: center;

      .checkout_star {
        padding-top: 7px;
        // width: 20px;
        margin-right: 2px;
        // height: 20px;
        // line-height: 20px;
        display: inline-block;
        vertical-align: middle;
        color: #eb3b2f;
        font-size: 14px;
      }
    }

    // border-right:1px solid #ccc
    .Introductory_title {
      // padding: 0 20px;
      width: 100%;
      height: 24px;
      display: flex;
      // justify-content: space-between;
      align-items: center;

      .item_text {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
        margin-left: 8px;
        font-weight: 600;
      }

      .img_box {
        width: 24px;
        height: 24px;
        // background-color: #C3CBCF;
        display: flex;
        align-items: center;

        // background-color: gray;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .title2 {
      .right {
        width: 100%;

        .inputBox {
          // width: 250px;
          width: 100%;

          .input-class-left {
            text-align: left !important;
            border: none;
            outline: none;
            box-shadow: none;
            font-size: 14px !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #111111 !important;
          }

          .input-class-right {
            text-align: right !important;
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .title1 {
      padding: 0px !important;
      // position: relative;
      border: none !important;
      width: 100%;

      .ai_select {
        width: 100%;
        /* 让ai_select下拉框铺满title1盒子的宽度 */
        text-align: left;

        /* 下拉框的内容左对齐 */
        .ai_img_module {
          width: 16px !important;
          /* 图片宽度为16px */
          height: 16px !important;
          /* 图片高度为16px */
          vertical-align: middle;
        }

        span {
          display: inline-block;
          /* 确保文字在图片右边 */
          margin-left: 12px;
          /* 文字在图片右边12px */
          vertical-align: middle;
          /* 文字与图片垂直居中对齐 */
        }
      }
    }

    .item {
      // height: 48px;
      height: 32px;
      margin-bottom: 16px;
      width: 100%;
      // background: #fafbfc;
      display: flex;
      align-items: center;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);

      .left {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606c80;

        // line-height: 20px;
        .iconBox {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 100%;
          // margin-right: 12px;

          img {
            // width: 24px;
            // height: 24px;
            width: 16px;
            height: 16px;
          }

          .txt {
            margin-left: 12px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #111111 !important;
          }
        }
      }

      .right {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        // line-height: 20px;
        .option-btn {
          margin-right: 10px;
          margin-bottom: 10px;
        }

        .option-image {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }

    .textArea_changeHeight {
      // height: 266px !important;
      height: 304px !important;
    }

    .textArea {
      width: 100%;
      background: #fafbfc;
      // height: 450px;
      // padding: 14px 20px;
      text-align: left;
      // height: 362px;
      height: 400px;

      .caption {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606c80;
      }

      .area {
        height: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        // overflow: auto;

        .area-box {
          // padding: 8px 0;
          // padding-bottom: 18px;
          height: 100%;
          display: flex;
          flex-direction: column;
          border: none;
          outline: none;
          box-shadow: none;
        }

        .ai-textarea {
          flex-grow: 1;
          border: none;
          outline: none;
          box-shadow: none;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }

    .creation-button {
      pointer-events: none !important;
      user-select: none;
      margin: 0 auto;
      // margin-top: 32px;
      margin-top: 30px;
      width: 400px;
      height: 48px;
      border-radius: 8px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: #BBBFC2;
      background: rgba(50, 113, 255, 0.5) !important;
    }

    // 输入完全部内容可以点击生成文档内容时
    .creation_creationFlag {
      pointer-events: auto !important;
      cursor: pointer !important;
      background: #3271ff !important;
    }

    .creation_creationLoadingFlag {
      pointer-events: none !important;
      background: rgba(50, 113, 255, 0.5) !important;
    }

    .creation_transferFlag {
      pointer-events: auto !important;
      cursor: pointer !important;
      background: #3271ff !important;
    }

    // .creation-button-false {
    //     background: #BBBFC2;
    // }

    .creation-button-tooltip {
      margin-top: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606c80;
    }
  }

  .content-right {
    padding: 20px;
    flex: 1;
    // margin: 0 auto;
    // margin-top: 16px;
    // width: 1000px;
    height: 100%;
    background: #ffffff;
    position: relative;

    .createFile_noInput {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .img_box {
        width: 160px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 160px;
          height: 160px;
        }
      }
    }

    .Introductory_title {
      // padding: 0 20px;
      width: 100%;
      height: 24px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 24px;

      .saveBtn_loading {
        cursor: pointer;

        background: #3271ff !important;
      }

      .saveBtn_default {
        pointer-events: none;
        background: rgba(50, 113, 255, 0.5) !important;
      }

      .saveBtn {
        position: absolute;
        // right: 20px;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 84px;
        height: 32px;

        border-radius: 4px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;

        .img_box {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .item_text {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
        margin-left: 8px;
        font-weight: 600;
      }

      .img_box {
        width: 24px;
        height: 24px;
        // background-color: #C3CBCF;
        display: flex;
        align-items: center;

        // background-color: gray;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .area-box-edit {
      max-width: 38vw;
      height: 100%;
      // //   // 编辑器外框最大高度
      //   max-width: 42vw;
      //   height: 535px;
      //   overflow: auto;
      //   display: flex;
      //   flex-direction: column;
    }
  }
}

#ai-doc-content {
  height: 535px;
  overflow: auto;
  border: 1px solid #ccc;
  padding: 10px;
  // cursor: url('../../assets/images/disabled.png'), 36*36;
}
.ai-doc-render {
  cursor: url("../../assets/images/disabled_24.png"), auto;
}
.ai-doc-edit {
  cursor: auto;
}
</style>
