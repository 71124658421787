var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AiCreationDocment"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"Introductory_title"},[_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":_vm.modulTypeObj.image,"alt":""}})]),_c('div',{staticClass:"item_text"},[_vm._v(_vm._s(_vm.modulTypeObj.value))])]),_c('div',{staticClass:"formTitle"},[_c('div',{staticClass:"checkout_star"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.radioOptions.find((obj) => obj.value == _vm.modulType)?.modulTitle)+"： ")]),_c('div',{staticClass:"item title2"},[_c('div',{staticClass:"right"},[_c('div',{staticClass:"inputBox"},[_c('a-col',{attrs:{"span":24}},[_c('a-input',{staticClass:"input-class-left",attrs:{"maxLength":50,"size":"small","placeholder":_vm.radioOptions.find((obj) => obj.value == _vm.modulType)
                  ?.placeholderTitle},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)],1)])]),(_vm.radioOptions.find((obj) => obj.value == _vm.modulType)?.modulTitle2)?_c('div',{staticClass:"formTitle"},[_c('div',{staticClass:"checkout_star"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.radioOptions.find((obj) => obj.value == _vm.modulType)?.modulTitle2)+"： ")]):_vm._e(),(_vm.radioOptions.find((obj) => obj.value == _vm.modulType)?.modulTitle2)?_c('div',{staticClass:"item title2"},[_c('div',{staticClass:"right"},[_c('div',{staticClass:"inputBox"},[_c('a-col',{attrs:{"span":24}},[_c('a-input',{staticClass:"input-class-left",attrs:{"maxLength":50,"size":"small","placeholder":_vm.radioOptions.find((obj) => obj.value == _vm.modulType)
                  ?.placeholderTitle2},model:{value:(_vm.inputValue2),callback:function ($$v) {_vm.inputValue2=$$v},expression:"inputValue2"}})],1)],1)])]):_vm._e(),_c('div',{staticClass:"formTitle"},[_c('div',{staticClass:"checkout_star"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.radioOptions.find((obj) => obj.value == _vm.modulType)?.modulArea)+"： ")]),_c('div',{staticClass:"textArea",class:_vm.modulType == '推广方案' ? 'textArea_changeHeight' : ''},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"area-box"},[_c('a-textarea',{staticClass:"ai-textarea",attrs:{"maxlength":500,"placeholder":_vm.radioOptions.find((obj) => obj.value == _vm.modulType)
                ?.placeholderArea},model:{value:(_vm.textAreaValue),callback:function ($$v) {_vm.textAreaValue=$$v},expression:"textAreaValue"}})],1)])]),_c('div',{staticClass:"creation-button",class:{
        creation_creationFlag: _vm.creationFlag,
        creation_creationLoadingFlag: _vm.creationLoadingFlag,
        creation_transferFlag: _vm.transferFlag,
      },on:{"click":_vm.createdDoc}},[_vm._v(" "+_vm._s(_vm.subBtnTxt)+" "),(_vm.creationLoadingFlag)?_c('div',{staticClass:"dot-box"},[_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"})]):_vm._e()])]),_c('div',{staticClass:"content-right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editcrCationFlag),expression:"!editcrCationFlag"}],staticClass:"createFile_noInput"},[_vm._m(0),_c('div',{staticClass:"empty_text"},[_vm._v("还没有内容生成")]),_c('div',{staticClass:"empty_text"},[_vm._v(" 在页面左侧输入您的内容诉求，快速生成您想要的文档内容 ")])]),_c('div',{staticClass:"Introductory_title"},[_vm._m(1),_c('div',{staticClass:"item_text"},[_vm._v("生成文档内容")]),_c('div',{staticClass:"saveBtn",class:!_vm.creationLoadingFlag &&
          _vm.editcrCationFlag &&
          _vm.transferFlag &&
          _vm.contentEmptyFlag
            ? 'saveBtn_loading'
            : 'saveBtn_default',on:{"click":_vm.saveFile}},[_vm._m(2),_c('div',[_vm._v("保 存")])])]),_c('div',{staticClass:"area-box-edit"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editcrCationFlag),expression:"editcrCationFlag"}],class:_vm.transferFlag ? 'ai-doc-edit' : 'ai-doc-render',attrs:{"contenteditable":_vm.transferFlag ? true : false,"id":"ai-doc-content"},domProps:{"innerHTML":_vm._s(_vm.docContentEdit)},on:{"input":_vm.handleContentChange}})])]),_c('aiFileModule',{ref:"aiFileModule",attrs:{"modulType":_vm.modulType,"inputValue":_vm.inputValue},on:{"submit":_vm.submit}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/images/ai_document_create_empty.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/images/ai_document_create.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/images/ai_document_save_btn.png"),"alt":""}})])
}]

export { render, staticRenderFns }