
<template>
    <div>
        <a-modal v-model="visible" title="保存文件" @ok="handleSubmit" @cancel="handleCancel">

            <!-- <a-spin :spinning="loading"> -->
            <a-form :form="form">
                <a-form-item label="文件名称" name="fileName" rules="required">
                    <a-input
                        v-decorator="['fileName', { rules: [{ required: true, message: '必填' }, { max: 50, message: '超过最大字数限制' },  { validator: validateNotEmpty, trigger: 'blur' }  ] }]"></a-input>
                </a-form-item>
                <a-form-item label="文件类型" name="fileType" rules="required" :rules="[{ required: true, message: '必填' }]">
                    <a-radio-group v-decorator="['fileType', { rules: [{ required: true, message: '必填' }] }]">
                        <a-radio value="PDF">PDF(.pdf)</a-radio>
                        <a-radio value="DOCX">Word(.docx)</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="保存路径" name="parentName" rules="required">
                    <a-input :disabed="true" @click="saveModalShow=true"
                        v-decorator="['parentName', { rules: [{ required: true, message: '必填' }, { max: 50, message: '超过最大字数限制' },  { validator: validateNotEmpty, trigger: 'blur' }  ] }]"></a-input>
                </a-form-item>
            </a-form>

            <!-- </a-spin> -->
        </a-modal>
         <!-- 保存确认 -->
      <SaveModal ref="SaveModalRef" v-if="saveModalShow" :visible="saveModalShow" type="save" :options="saveOptions"
        @close="saveModalShow = false" @saves="saveOk" />
    </div>
</template>
  
<script>
import SaveModal from "@/views/popup/moveModal.vue";

export default {
    components:{
        SaveModal
    },
    data() {
        return {
            selectId:'',
            teamId:'',
            saveOptions: {
        checkIdList: []
      },
            saveModalShow:false,
            // loading:false,
            form:{
               
                
            },
            visible: false

        };
    },
    props: ['inputValue', 'modulType', 'loading'], // 接收父组件传递的formData属性
    mounted() {
        // console.log("🚀 ~ file: aiFileModule.vue:36 ~ mounted ~  this.formData:",  this.formData)
        this.form = this.$form.createForm(this);
      
    },
    watch: {

        visible(val) {
            if (!val) {
                this.form.resetFields();
            }
        }
    },
    methods: {
        saveOk({userFilePath,userFileName,id,parentTitle,teamId}){
            console.log(id)
            this.form.setFieldsValue({
                parentName:parentTitle+(userFilePath||'')+(userFileName||'')
            })
            this.selectId = id;
            this.teamId = teamId
            this.saveModalShow=false
        },
        show() {
            this.visible = true;
         
            if (this.inputValue && this.modulType) {

                this.$nextTick(() => {
                    if (this.modulType == '生成文档' || this.modulType == '论文写作') {
                        this.form.setFieldsValue({
                            fileName: this.inputValue
                        }); // 设置表单字段的初始值
                    } else {
                        this.form.setFieldsValue({
                            fileName: `${this.modulType}-${this.inputValue}`
                        }); // 设置表单字段的初始值
                    }

                })
            }
            this.$nextTick(()=>{
            this.form.setFieldsValue({
                fileType:'PDF',
                parentName:'个人文档'
                        }); 
        })
        },
        // handleOk() {
        //     this.form.validateFields((err, values) => {
        //         console.log("🚀 ~ file: aiFileModule.vue:52 ~ this.form.validateFields ~ values:", values)
        //         if (!err) {
        //             this.$emit('submit', values);
        //             this.visible = false;
        //         }
        //     });
        // },
        validateNotEmpty(rule, value, callback) {
            if (!value || value.trim() === '') {
                callback(new Error('文件名称不能为空'));
            } else {
                callback();
            }
        },
        handleSubmit() {
            // e.preventDefault();

            this.form.validateFields((err, values) => {
                if (!err) {
                    // this.loading=true
                    this.visible = false;
                    console.log('Received values of form: ', values);
                    const teamId = this.teamId===''?null:this.teamId
                    const moduleType = this.teamId===''?'doc':'team'
                    this.$emit('submit', {...values,parentId:this.selectId||'',teamId,moduleType});
                }
            });
        },
        handleCancel() {
            this.visible = false;
        }
    }
};
</script>
  